<template>
  <div class="container" v-if="!isLoading">
    <div class="card-list">
      <div class="card bind-steam-card" v-if="!player || !player.steam_account">
        <div class="card-content">
          <router-link :to="{ name: 'BindSteam' }"> <van-button block class="blue-btn" type="primary">绑定SteamID</van-button></router-link>
          <p>绑定SteamID后即可解锁个人游戏数据功能</p>
        </div>
      </div>

      <template v-else>
        <div class="match-detail" >天命竞技场</div>
        <div class="card1">
          <div class="card-header">
            <div class="game-logo">
              <van-circle v-model:current-rate="currentRate" :rate="currentRate" :speed="100"  color="rgb(13,193,96)">
                <div class="custom-text">
                  <span>{{ rateText }}</span>
                  <span> 胜率 </span>
                </div>
              </van-circle>
            </div>
            <div >
              <div class="game-name">
                <span class="label">总场数</span>
                <span class="game-number">{{gameData.game_summaries.total_count ? gameData.game_summaries.total_count : 0}}</span>
              </div>
              <div class="game-name">
                <span class="label">吃鸡数</span>
                <span class="game-number">{{gameData.game_summaries.rank1_count ? gameData.game_summaries.rank1_count : 0 }}</span>
              </div>
              <div class="game-name">
                <span class="label">获胜数</span>
                <span class="game-number">{{gameData.game_summaries.win_count ? gameData.game_summaries.win_count : 0}}</span>
              </div>
            </div>
          </div>
          <div class="card-content">
            <div class="data-item">
              <div class="data-value">{{  gameData.game_summaries.kill_count ? gameData.game_summaries.kill_count : '-' }}</div>
              <div class="data-label">击杀数</div>
            </div>
            <div class="data-item">
              <div class="data-value">{{  gameData.game_summaries.dead_count ? gameData.game_summaries.dead_count : '-' }}</div>
              <div class="data-label">死亡数</div>
            </div>
            <div class="data-item">
              <div class="data-value">{{  gameData.game_summaries.assist_count ? gameData.game_summaries.assist_count : '-' }}</div>
              <div class="data-label">助攻数</div>
            </div>
            <div class="data-item">
              <div class="data-value">{{  gameData.game_summaries.revive_count ? gameData.game_summaries.revive_count : '-' }}</div>
              <div class="data-label">救援数</div>
            </div>
            <div class="data-item">
              <div class="data-value">{{  gameData.game_summaries.pick_lotus_count ? gameData.game_summaries.pick_lotus_count : '-' }}</div>
              <div class="data-label">莲花数</div>
            </div>
            <div class="data-item">
              <div class="data-value">{{  gameData.game_summaries.total_damage ? gameData.game_summaries.total_damage : '-' }}</div>
              <div class="data-label">总伤害</div>
            </div>
          </div>
        </div>
      </template>
      <van-list v-model:loading="isLoading" >
<!--        <div class="card2" v-for="item in list" :key="item.rank">-->
<!--          <div class="card-right">-->
<!--            <div class="card-content">-->
<!--             <div class="data-item" style="width: 20%">-->
<!--                <div class="data-value">第{{ item.ranking }}名</div>-->
<!--              </div>-->
<!--              <div class="data-item" style="width: 20%">-->
<!--                <div class="data-value">{{ getScore(item.add_score) }}分</div>-->
<!--              </div>-->
<!--              <div class="data-item" style="width: 40%">-->
<!--                <div class="data-value">{{ timestampToDateFormat(item.start_time)}}</div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
      </van-list>

    </div>

  </div>
</template>
<script>

export default {
  name: 'C5',
}
</script>
<script setup>
import { computed, onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import { playerC5Data } from '@/api/gameData'
import { Button,Circle} from 'vant'
const VanButton = Button
const VanCircle = Circle
const store = useStore()
const isLoading = ref(true)
const player = ref({})
const gameData = ref([])
const list = ref([])
const Text = ref("历史对局")
const currentRate = ref(0);
const rateText = computed(() => currentRate.value.toFixed(0) + '%');

onMounted(() => {
  initData()
})


const initData = () => {
  store.dispatch('player/getInfo').then(res => {
    player.value = res.data.player
    isLoading.value = false
  })
  getPlayerData()
}
const getScore = (item) => {
  if (item > 0) {
    item = '+' + item
  }
  return item
}
const getPlayerData = () => {
  playerC5Data().then(res => {
    gameData.value = res.data.gameData
    // list.value = res.data.gameData.player_match
    currentRate.value = res.data.gameData.game_summaries.total_count > 0 ? (res.data.gameData.game_summaries.win_count / res.data.gameData.game_summaries.total_count * 100) : 0
  })
}
</script>

<style lang="scss" scoped>
.card-list {
  display: flex;
  flex-flow: column;
  align-items: center;
}

.card1 {
  margin: 3px 5px;
  box-sizing: border-box;
  border-top: 1px solid var(--app-line-color);
  width: 99vw;
  padding: 10px 20px;
  display: inline-block;
  position: relative;
  text-align: center;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05), 0 20px 50px 0 rgba(0, 0, 0, 0.1);
  z-index: 1;
  background: #2d2d35;
}
.card2 {
  margin: 0 5px;
  box-sizing: border-box;
  border-top: 1px solid var(--app-line-color);
  width: 99vw;
  padding: 10px 20px;
  display: inline-block;
  position: relative;
  text-align: center;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05), 0 20px 50px 0 rgba(0, 0, 0, 0.1);
  z-index: 1;
  background: #2d2d35;
}

.card-header {
  padding-bottom: 10px;
  display: flex;
  border-radius: 10px 10px 0 0;
  align-items: center;
  border-bottom: 1px solid #424450;
}

/*.card-header:after {*/
/*    font-family: "iconfont";*/
/*    content: "\e666";*/
/*    font-weight: 600;*/
/*    color:#b0b5cb;*/
/*    margin-left: auto;*/
/*}*/
.custom-text {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.custom-text span {
  font-size: 14px;
  color: #fff;
  font-weight: bold;
}

.game-name {
  margin: 10px 10px 10px;
  font-size: 15px;
  width: 200px;
  height: 18px;
  line-height: 100%;
  background-color: rgb(30,33,52);
  color: #fff;
  font-weight: 600;
  display: block;
}
.game-number {
  float: right;
}
.card-content {
  display: flex;
  justify-content: space-around;
  color: #fff;
  flex-wrap: wrap;
}

.card-content .data-item {
  padding-top: 5px;
  text-align: center;
  width: 33.3%;
  position: relative;
}

.card-content .data-label {
  font-size: 12px;
  font-weight: 600;
  color: #7c8197;
}

.card-content .data-value {
  font-size: 15px;
  font-weight: 500;
  color: #d2d8e8;
}

.bind-steam-card {
  padding: 50px 0;
  position: relative;
}

.bind-steam-card::after {
  content: '';
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: url('/images/common/tui6-logo.jpg') no-repeat center;
  background-size: cover;
  opacity: 0.3;
  filter: blur(3px);
}

.bind-steam-card .blue-btn {
  border-radius: 20px;
  width: 200px;
}
.match-detail{
  margin: 2vw;
  font-size: 4vw;
  color: white;
}
.bind-steam-card p {
  color: #b0b4c9;
  font-size: 12px;
  padding-top: 25px;
}
.match-item {
  padding: 10px 0;
}
.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.ranking {
  flex-shrink: 0;
  width: 40px;
  color: #d2d8e8;
}
.card-content {
  //padding-top: 5px;
  display: flex;
  justify-content: space-around;
  color: #fff;
  flex-wrap: wrap;
  align-items: center;
}
</style>
